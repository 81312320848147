import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';

import {
  Link,
  Skeleton,
  Stack,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  Button,
  Avatar
} from '@mui/material';
import {
  AddCard,
  AutoStories,
  CorporateFare,
  EmojiEmotions,
  Groups,
  HistoryEdu,
  HowToReg,
  Login,
  Person,
  SaveAlt,
  School,
  ShoppingCart,
  SupervisorAccountSharp,
  SwapHoriz,
  WorkspacePremium,
  Logout
} from '@mui/icons-material';
import { IconBrandDiscord } from '@tabler/icons-react';

import { AuthService, UserType } from '../client';
import { useAppContext } from '../context/AppContext';
import { useGenericModal } from '../context/GenericModalContext';
import { useLocaleContext } from '../context/LocaleContext';
import { UserTypeMap } from '../pages/App';
import { useCurrentUser } from '../hooks/useCurrentUser';
import StudentAvatar from './StudentAvatar';
import { LocaleSelect } from './LocaleSelect/LocaleSelect';
import { GoogleIcon } from '../components/GoogleIcon';
import { MessageBox } from './GameUI/InGamePopUp';
import PrimaryButton from './GameUI/PrimaryButton';
import { useTranslation } from 'react-i18next';

function LogInTooltip() {
  return (
    <Box
      component="div"
      sx={{
        fontFamily: 'Urbanist, sans-serif',
        fontSize: '18px',
        fontWeight: 600,
        backgroundColor: '#DCBB8B',
        border: '2px solid #E6D4AF',
        outline: '2px solid #AF8B68',
        backgroundImage: 'none',
        padding: 3,
        pb: 2,
        borderRadius: '10px',
        textAlign: 'center'
      }}
    >
      <MessageBox>
        <Typography variant="body1">Log in to store progress and code</Typography>
      </MessageBox>
      <PrimaryButton
        href="/api/auth/google/authorize"
        variant="contained"
        fullWidth
        sx={{ mt: 3, fontSize: '14px' }}
        size="small"
        startIcon={<GoogleIcon />}
      >
        Log In with Google
      </PrimaryButton>
    </Box>
  );
}

export default function AccountMenu() {
  const { t } = useTranslation();

  const { setOpenChooseStudent } = useAppContext();

  const { getLocaleLink } = useLocaleContext();

  const { setOpenModal: setOpenAvatarSelectorGlobalModal } = useGenericModal(
    'avatar-selector-global-modal'
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const queryClient = useQueryClient();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { currentUser, isCurrentUserLoading } = useCurrentUser();
  const currentStudent = currentUser?.student_details;
  const [logInTooltipOpen, setLogInTooltipOpen] = useState(false);

  const logOut = useMutation(AuthService.logoutApiAuthLogoutGet, {
    onSuccess: () => {
      queryClient.invalidateQueries('currentUser');
      document.location.href = getLocaleLink('/');
    }
  });

  useEffect(() => {
    if (localStorage.getItem('login-tooltip-shown')) {
      return;
    }
    const timerId = setTimeout(() => {
      localStorage.setItem('login-tooltip-shown', 'true');
      setLogInTooltipOpen(true);
    }, 30000);

    return () => clearTimeout(timerId);
  }, []);

  const userType = UserTypeMap[currentUser?.user_type || UserType._0];

  const studentsLink = currentUser?.organization_id
    ? getLocaleLink(`/organization/students`)
    : getLocaleLink(`/profile/students`);

  return (
    <>
      <Box
        component="div"
        sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', gap: 1 }}
      >
        <LocaleSelect />
        {/* <IconButton
          component={Link}
          target="_blank"
          href="https://discord.gg/dFB8zMrSmB"
          size="small"
        >
          <IconBrandDiscord />
        </IconButton> */}

        <Button
          variant="outlined"
          component={Link}
          target="_blank"
          href="https://coding-for-kids.upvoty.com/b/feature-requests/"
          size="small"
          width={180}
          color="warning"
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          Vote for features
        </Button>
        {isCurrentUserLoading ? (
          <Skeleton variant="circular" width={32} height={32} sx={{ ml: 2 }} />
        ) : currentUser !== undefined ? (
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              {currentStudent ? (
                <StudentAvatar student={currentStudent} size={32} />
              ) : (
                <Avatar alt={currentUser.email}>
                  {currentUser.email?.substring(0, 1)?.toUpperCase()}
                </Avatar>
              )}
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip
            title={<LogInTooltip />}
            onOpen={() => setLogInTooltipOpen(true)}
            onClose={() => setLogInTooltipOpen(false)}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'transparent'
                }
              }
            }}
          >
            <Box component="div" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Button
                sx={{ display: { xs: 'none', md: 'flex' } }}
                size="small"
                variant="outlined"
                startIcon={<HowToReg />}
                href={getLocaleLink('/signup')}
              >
                Sign Up
              </Button>
              <Button
                size="small"
                sx={{ width: 100 }}
                variant="contained"
                startIcon={<Login />}
                href={getLocaleLink('/login')}
              >
                Log In
              </Button>
            </Box>
          </Tooltip>
        )}
      </Box>
      {currentUser?.is_student ? (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {currentStudent && (
            <MenuItem
              sx={{ minWidth: 220 }}
              component={NavLink}
              to={getLocaleLink(`/profile/students/${currentStudent.id}`)}
            >
              <StudentAvatar student={currentStudent} size={32} variant="body1" />
            </MenuItem>
          )}
          <Divider />
          <MenuItem component={Link} href={getLocaleLink(`/profile/student/${currentStudent?.id}`)}>
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>
            {t('accountMenu.myProfile')}
          </MenuItem>
          <MenuItem onClick={() => setOpenAvatarSelectorGlobalModal(true)}>
            <ListItemIcon>
              <EmojiEmotions fontSize="small" />
            </ListItemIcon>
            {t('accountMenu.chooseAvatar')}
          </MenuItem>
          <MenuItem component={Link} href={getLocaleLink(`/courses`)}>
            <ListItemIcon>
              <AutoStories fontSize="small" />
            </ListItemIcon>
            {t('accountMenu.chooseCourse')}
          </MenuItem>
          <MenuItem component={Link} href={getLocaleLink(`/certificates/${currentStudent?.id}`)}>
            <ListItemIcon>
              <HistoryEdu fontSize="small" />
            </ListItemIcon>
            {t('accountMenu.myCertificates')}
          </MenuItem>
          <MenuItem component={Link} href={getLocaleLink(`/badges/${currentStudent?.id}`)}>
            <ListItemIcon>
              <WorkspacePremium fontSize="small" />
            </ListItemIcon>
            {t('accountMenu.myBadges')}
          </MenuItem>
          <MenuItem onClick={() => logOut.mutate()}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Log Out
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem sx={{ minWidth: 220 }}>{currentUser?.email}</MenuItem>
          {currentStudent && !currentUser?.organization_id && (
            <MenuItem
              sx={{ minWidth: 220 }}
              component={NavLink}
              to={`/profile/students/${currentStudent.id}`}
            >
              <StudentAvatar student={currentStudent} size={32} variant="body1" />
            </MenuItem>
          )}
          {currentStudent && currentUser?.organization_id && (
            <MenuItem
              sx={{ minWidth: 220 }}
              component={NavLink}
              to={getLocaleLink(`/organization/students/${currentStudent.id}`)}
            >
              <StudentAvatar
                student={currentStudent}
                size={32}
                variant="body1"
                classDetails={currentUser?.class_details}
              />
            </MenuItem>
          )}
          <MenuItem onClick={() => setOpenChooseStudent(true)}>
            <ListItemIcon>
              <SwapHoriz fontSize="small" />
            </ListItemIcon>
            {t('accountMenu.chooseStudent')}
          </MenuItem>
          {currentStudent && (
            <MenuItem onClick={() => setOpenAvatarSelectorGlobalModal(true)}>
              <ListItemIcon>
                <EmojiEmotions fontSize="small" />
              </ListItemIcon>
              {t('accountMenu.chooseAvatar')}
            </MenuItem>
          )}
          {currentStudent && (
            <MenuItem component={Link} href={getLocaleLink(`/certificates/${currentStudent?.id}`)}>
              <ListItemIcon>
                <HistoryEdu fontSize="small" />
              </ListItemIcon>
              {t('accountMenu.myCertificates')}
            </MenuItem>
          )}
          {currentStudent && (
            <MenuItem component={Link} href={getLocaleLink(`/courses`)}>
              <ListItemIcon>
                <AutoStories fontSize="small" />
              </ListItemIcon>
              {t('accountMenu.chooseCourse')}
            </MenuItem>
          )}
          {currentStudent && (
            <MenuItem component={Link} href={getLocaleLink(`/badges/${currentStudent?.id}`)}>
              <ListItemIcon>
                <WorkspacePremium fontSize="small" />
              </ListItemIcon>
              {t('accountMenu.myBadges')}
            </MenuItem>
          )}
          <MenuItem component={Link} href={getLocaleLink('/profile/details')}>
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>
            {t('accountMenu.myProfile')}
          </MenuItem>
          {userType === 'admin' && (
            <MenuItem component={Link} href={getLocaleLink('/organization/details')}>
              <ListItemIcon>
                <CorporateFare fontSize="small" />
              </ListItemIcon>
              {t('accountMenu.organizationDetails')}
            </MenuItem>
          )}
          {userType === 'admin' && (
            <MenuItem component={NavLink} to={getLocaleLink('/organization/teachers')}>
              <ListItemIcon>
                <SupervisorAccountSharp fontSize="small" />
              </ListItemIcon>
              {t('accountMenu.teachers')}
            </MenuItem>
          )}
          <MenuItem component={Link} href={studentsLink}>
            <ListItemIcon>
              <Groups fontSize="small" />
            </ListItemIcon>
            {t('accountMenu.students')}
          </MenuItem>
          {(userType === 'admin' || userType === 'parent') && (
            <MenuItem component={Link} href={getLocaleLink('/profile/billing')}>
              <ListItemIcon>
                <AddCard fontSize="small" />
              </ListItemIcon>
              {t('accountMenu.billing')}
            </MenuItem>
          )}
          {/*<MenuItem onClick={handleClose}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <School fontSize="small" />*/}
          {/*  </ListItemIcon>*/}
          {/*  Courses*/}
          {/*</MenuItem>*/}
          {/*<MenuItem onClick={handleClose}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <BarChart fontSize="small" />*/}
          {/*  </ListItemIcon>*/}
          {/*  Statistics*/}
          {/*</MenuItem>*/}
          <Divider />
          {/*<MenuItem onClick={openSubscriptionCheckout}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <AddCard fontSize="small" />*/}
          {/*  </ListItemIcon>*/}
          {/*  Subscribe*/}
          {/*</MenuItem>*/}
          <MenuItem component={Link} href={getLocaleLink('/purchase')}>
            <ListItemIcon>
              <ShoppingCart fontSize="small" />
            </ListItemIcon>
            {t('sidebar.buyCourse')}
          </MenuItem>
          <MenuItem component={Link} href={getLocaleLink('/schools')}>
            <ListItemIcon>
              <School fontSize="small" />
            </ListItemIcon>
            {t('accountMenu.forInstitutions')}
          </MenuItem>
          <MenuItem onClick={() => logOut.mutate()}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Log Out
          </MenuItem>
        </Menu>
      )}
    </>
  );
}
