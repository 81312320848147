/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ExecutionType {
    LEVEL = 'level',
    CHALLENGE = 'challenge',
    LEVEL_ASSISTANT = 'level_assistant',
    CHALLENGE_ASSISTANT = 'challenge_assistant',
}
