/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BulkCreateOrganizationStudentForm } from '../models/BulkCreateOrganizationStudentForm';
import type { Class } from '../models/Class';
import type { ClassTeacher } from '../models/ClassTeacher';
import type { ClassTeacherForm } from '../models/ClassTeacherForm';
import type { CreateClassForm } from '../models/CreateClassForm';
import type { CreateOrganizationStudentForm } from '../models/CreateOrganizationStudentForm';
import type { CreateOrganizationTeacherForm } from '../models/CreateOrganizationTeacherForm';
import type { Organization } from '../models/Organization';
import type { OrganizationInput } from '../models/OrganizationInput';
import type { Student } from '../models/Student';
import type { StudentListItem } from '../models/StudentListItem';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationService {

    /**
     * Current Organization
     * @returns Organization Successful Response
     * @throws ApiError
     */
    public static currentOrganizationApiOrganizationCurrentGet(): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organization/current',
        });
    }

    /**
     * Update Organization
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateOrganizationApiOrganizationCurrentPut(
        requestBody: OrganizationInput,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/organization/current',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Check Organization Slug Exists
     * @param slug
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static checkOrganizationSlugExistsApiOrganizationCheckSlugExistsGet(
        slug: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organization/check-slug-exists',
            query: {
                'slug': slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Teacher
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createTeacherApiOrganizationTeacherPost(
        requestBody: CreateOrganizationTeacherForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/organization/teacher',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Teacher
     * @param teacherId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateTeacherApiOrganizationTeacherTeacherIdPut(
        teacherId: string,
        requestBody: CreateOrganizationTeacherForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/organization/teacher/{teacher_id}',
            path: {
                'teacher_id': teacherId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Teacher
     * @param teacherId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteTeacherApiOrganizationTeacherTeacherIdDelete(
        teacherId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/organization/teacher/{teacher_id}',
            path: {
                'teacher_id': teacherId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Teachers By Organization
     * @returns User Successful Response
     * @throws ApiError
     */
    public static getTeachersByOrganizationApiOrganizationTeachersGet(): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organization/teachers',
        });
    }

    /**
     * Get Organization Teacher
     * @param teacherId
     * @returns User Successful Response
     * @throws ApiError
     */
    public static getOrganizationTeacherApiOrganizationTeachersTeacherIdGet(
        teacherId: string,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organization/teachers/{teacher_id}',
            path: {
                'teacher_id': teacherId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Classes By Organization
     * @returns Class Successful Response
     * @throws ApiError
     */
    public static getClassesByOrganizationApiOrganizationClassesGet(): CancelablePromise<Array<Class>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organization/classes',
        });
    }

    /**
     * Create Class
     * @param requestBody
     * @returns Class Successful Response
     * @throws ApiError
     */
    public static createClassApiOrganizationClassesPost(
        requestBody: CreateClassForm,
    ): CancelablePromise<Class> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/organization/classes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Organization Class
     * @param classId
     * @returns Class Successful Response
     * @throws ApiError
     */
    public static getOrganizationClassApiOrganizationClassesClassIdGet(
        classId: string,
    ): CancelablePromise<Class> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organization/classes/{class_id}',
            path: {
                'class_id': classId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Class
     * @param classId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteClassApiOrganizationClassesClassIdDelete(
        classId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/organization/classes/{class_id}',
            path: {
                'class_id': classId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Class
     * @param classId
     * @param requestBody
     * @returns Class Successful Response
     * @throws ApiError
     */
    public static updateClassApiOrganizationClassesClassIdPut(
        classId: string,
        requestBody: CreateClassForm,
    ): CancelablePromise<Class> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/organization/classes/{class_id}',
            path: {
                'class_id': classId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Class Teacher
     * @param classId
     * @param userId
     * @returns ClassTeacher Successful Response
     * @throws ApiError
     */
    public static getClassTeacherApiOrganizationClassTeachersClassIdUserIdGet(
        classId: string,
        userId: string,
    ): CancelablePromise<ClassTeacher> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organization/class-teachers/{class_id}/{user_id}',
            path: {
                'class_id': classId,
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Class Teachers
     * @param classId
     * @returns ClassTeacher Successful Response
     * @throws ApiError
     */
    public static getClassTeachersApiOrganizationClassTeachersClassIdGet(
        classId: string,
    ): CancelablePromise<Array<ClassTeacher>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organization/class-teachers/{class_id}',
            path: {
                'class_id': classId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Class Teacher
     * @param requestBody
     * @returns ClassTeacher Successful Response
     * @throws ApiError
     */
    public static updateClassTeacherApiOrganizationClassTeachersPut(
        requestBody: ClassTeacherForm,
    ): CancelablePromise<ClassTeacher> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/organization/class-teachers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Invite Teacher
     * @param email
     * @returns any Successful Response
     * @throws ApiError
     */
    public static inviteTeacherApiOrganizationInviteTeacherPost(
        email: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/organization/invite-teacher',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Students
     * @returns StudentListItem Successful Response
     * @throws ApiError
     */
    public static getStudentsApiOrganizationStudentsGet(): CancelablePromise<Array<StudentListItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organization/students',
        });
    }

    /**
     * Create Student
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createStudentApiOrganizationStudentsPost(
        requestBody: CreateOrganizationStudentForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/organization/students',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Student
     * @param studentId
     * @returns Student Successful Response
     * @throws ApiError
     */
    public static getStudentApiOrganizationStudentsStudentIdGet(
        studentId: string,
    ): CancelablePromise<Student> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organization/students/{student_id}',
            path: {
                'student_id': studentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Student
     * @param studentId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateStudentApiOrganizationStudentsStudentIdPut(
        studentId: string,
        requestBody: CreateOrganizationStudentForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/organization/students/{student_id}',
            path: {
                'student_id': studentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Student
     * @param studentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteStudentApiOrganizationStudentsStudentIdDelete(
        studentId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/organization/students/{student_id}',
            path: {
                'student_id': studentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Create Students
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static bulkCreateStudentsApiOrganizationBulkCreateStudentsPost(
        requestBody: BulkCreateOrganizationStudentForm,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/organization/bulk-create-students',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
