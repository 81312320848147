import { createContext, FC, ReactNode, useContext, useState } from 'react';
import Cookies from 'js-cookie';
import {
  ApiError,
  ChallengeData,
  ChatGPTRequest,
  ChatGPTRequestLevels,
  ChatgptService,
  LevelData,
  ChallengeTipResponseParsed,
  LevelTipResponseParsed
} from '../client';
import { useCode } from './CodeContext';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { useParams } from 'react-router-dom';

export const VirtualAssistantContext = createContext<{
  challengeTip?: ChallengeTipResponseParsed;
  levelTip?: LevelTipResponseParsed;
  setChallengeTip: (tip: ChallengeTipResponseParsed) => void;
  setLevelTip: (tip: LevelTipResponseParsed) => void;
  tipVisible: boolean;
  setTipVisible: (visible: boolean) => void;
  generateTipChallengeAsync?: (data: ChallengeData, errorMessage?: string | null) => Promise<void>;
  generateTipLevelAsync: (
    data: LevelData,
    playerPosition: number[],
    facingDirection: number[],
    errorMessage?: string | null
  ) => Promise<void>;
  setIsTipSwitcherActive: (active: boolean) => void;
  isActivationsExceeded: boolean;
  isTipSwitcherActive: boolean;
  playerPosition?: number[];
  facingDirection?: number[];
  setPlayerPosition: (position: number[]) => void;
  setFacingDirection: (direction: number[]) => void;
}>({
  challengeTip: undefined,
  levelTip: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setChallengeTip: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLevelTip: () => {},
  tipVisible: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTipVisible: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  generateTipChallengeAsync: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  generateTipLevelAsync: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsTipSwitcherActive: () => {},
  isActivationsExceeded: false,
  isTipSwitcherActive: false,
  playerPosition: [0, 0],
  facingDirection: [0, -1],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPlayerPosition: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFacingDirection: () => {}
});

export const VirtualAssistantProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [challengeTip, setChallengeTip] = useState<ChallengeTipResponseParsed>();
  const [levelTip, setLevelTip] = useState<LevelTipResponseParsed>();
  const [tipVisible, setTipVisible] = useState(false);
  const [isActivationsExceeded, setIsActivationsExceeded] = useState(false);
  const [isTipSwitcherActive, setIsTipSwitcherActive] = useState<boolean>(
    Cookies.get('isTipSwitcherActive') === 'true' || !Cookies.get('isTipSwitcherActive')
  );
  const [playerPosition, setPlayerPosition] = useState<number[]>([0, 0]);
  const [facingDirection, setFacingDirection] = useState<number[]>([0, -1]);

  const { courseSlug, levelSlug } = useParams();
  const { currentUser } = useCurrentUser();
  const { code, codeRef } = useCode();

  const generateTipChallengeAsync = async (data: ChallengeData, errorMessage?: string | null) => {
    if (!data) return;
    try {
      const chatGPTRequest: ChatGPTRequest = {
        description: data.description,
        solution: data.solution,
        last_prompt: code,
        error_message: errorMessage || null,
      };

      const tipRes =
        await ChatgptService.generateTipChallengeAsyncApiChatgptGenerateTipChallengeAsyncPost(
          chatGPTRequest
        );
      setChallengeTip(tipRes);
    } catch (e) {
      handleTipError(e, "challenge");
    }
  };

  const generateTipLevelAsync = async (
    data: LevelData,
    playerPosition: number[],
    facingDirection: number[],
    errorMessage?: string | null
  ) => {
    if (!data || !isTipSwitcherActive) return;
    try {
      const chatGPTRequest: ChatGPTRequestLevels = {
        last_prompt: codeRef.current,
        course_name: courseSlug || 'python',
        slug: levelSlug || 'intro-level1',
        player_position: playerPosition,
        facing_direction: facingDirection,
        error_message: errorMessage || null,
      };

      const tipRes =
        await ChatgptService.generateTipLevelAsyncApiChatgptGenerateTipLevelAsyncPost(
          chatGPTRequest
        );
      setLevelTip(tipRes);
    } catch (e) {
      handleTipError(e, "level");
    }
  };

  const handleTipError = (e: unknown, tipType: "challenge" | "level") => {
    const error = e as ApiError;
    const statusCode = error.status;
    const errorText = currentUser?.student_id
      ? 'You are out of credits for the coding assistant! Please upgrade to get unlimited credits so that I can continue helping you.'
      : 'You are out of credits for the coding assistant! Please sign up to get more free credits so that I can continue helping you.';
  
    const defaultTip = {
      text: errorText,
      code_to_add: '',
      line_number: 0,
      resulted_code: '',
      is_code_sufficient: false,
    };
  
    if (statusCode === 403) {
      setIsActivationsExceeded(true);
  
      if (tipType === "challenge") {
        setChallengeTip(defaultTip);
      } else {
        setLevelTip(defaultTip);
      }
    } else {
      console.error('Error generating tip:', error);
      const generalErrorTip = {
        ...defaultTip,
        text: 'Failed to fetch the tip. Please try again later.'
      };
  
      if (tipType === "challenge") {
        setChallengeTip(generalErrorTip);
      } else {
        setLevelTip(generalErrorTip);
      }
    }
  };
  
  

  return (
    <VirtualAssistantContext.Provider
      value={{
        challengeTip,
        levelTip,
        setChallengeTip,
        setLevelTip,
        tipVisible,
        setTipVisible,
        generateTipChallengeAsync,
        generateTipLevelAsync,
        isActivationsExceeded,
        isTipSwitcherActive,
        setIsTipSwitcherActive,
        playerPosition,
        setPlayerPosition,
        facingDirection,
        setFacingDirection
      }}
    >
      {children}
    </VirtualAssistantContext.Provider>
  );
};

export const useVirtualAssistantContext = () => {
  return useContext(VirtualAssistantContext);
};
