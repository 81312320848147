/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EmailPreferences {
    NONE = 'none',
    ACCOUNT = 'account',
    MARKETING = 'marketing',
}
