import React, { createContext, useState, useEffect } from 'react';
import { useConfirm } from 'material-ui-confirm';

import { Command } from '../@core/utils/movementHelpers';
import { Box } from '@mui/material';
import { useApiLevelData } from './ApiLevelDataContext';
import { useOutput } from './OutputContext';
import waitForMs from '../@core/utils/waitForMs';

export interface ErrorAnnotation {
  row: number;
  column: number;
  text: string;
  type: 'error' | 'warning' | 'info';
}

export interface RunScriptResult {
  results: any;
  commands: Command[];
  constants: any;
  updatedConstants?: { [key: string]: any };
}

export interface PyodideContextType {
  runScript: (
    code: string,
    mainExecution?: boolean,
    fetchCommands?: boolean,
    fetchConstants?: boolean
  ) => Promise<RunScriptResult>;
  isLoading: boolean;
  errorAnnotations: ErrorAnnotation[];
  setErrorAnnotations: React.Dispatch<React.SetStateAction<ErrorAnnotation[]>>;
  errorMessage: string | null;
  setErrorMessageState: React.Dispatch<React.SetStateAction<string | null>>;
  errorMessageRef?: React.MutableRefObject<string | null>;
  setErrorMessage: (message?: string | null) => void;
  isCodeRunning: boolean;
  setIsCodeRunning: React.Dispatch<React.SetStateAction<boolean>>;
  stopCodeExecution: () => void;
  continueExecution: (code: string) => Promise<void>;
  postMessageToWorker: (message: any) => void;
  isRunningInitiated: boolean;
  setIsRunningInitiated: React.Dispatch<React.SetStateAction<boolean>>;
}

const PyodideContext = createContext<PyodideContextType>({
  runScript: async () => {
    throw new Error('Pyodide worker is not initialized.');
  },
  isLoading: false,
  errorAnnotations: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setErrorAnnotations: () => {},
  errorMessage: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setErrorMessage: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setErrorMessageState: () => {},
  isCodeRunning: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsCodeRunning: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  stopCodeExecution: () => {},
  continueExecution: async () => {
    throw new Error('Pyodide worker is not initialized.');
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  postMessageToWorker: () => {},
  isRunningInitiated: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsRunningInitiated: () => {}
});

interface PyodideProviderProps {
  children: React.ReactNode;
}

export const PyodideProvider: React.FC<PyodideProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCodeRunning, setIsCodeRunning] = useState<boolean>(false);
  const [isRunningInitiated, setIsRunningInitiated] = useState<boolean>(false);
  const [errorAnnotations, setErrorAnnotations] = useState<ErrorAnnotation[]>([]);
  const [errorMessage, setErrorMessageState] = useState<string | null>(null);
  const errorMessageRef = React.useRef<string | null>();
  const { apiLevelData } = useApiLevelData();
  const {
    resetCommandsQueue,
    setIsCodeExecuted,
    setCommandsInQueue,
    commandInProgress,
    setLastCommandInProgress,
    setPath,
    isProcessingLocked
  } = useOutput();
  const confirm = useConfirm();

  const workerRef = React.useRef<Worker | null>(null);
  let interruptBuffer: SharedArrayBuffer | ArrayBuffer;

  if (typeof SharedArrayBuffer !== 'undefined') {
    interruptBuffer = new SharedArrayBuffer(1);
  } else {
    interruptBuffer = new ArrayBuffer(1);
  }

  const interruptFlag = new Int8Array(interruptBuffer); // Flag to track if interruption is needed
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null); // To store the timeout reference
  const workerReset = React.useRef<boolean>(false);

  const executionInterruptMessage =
    'Execution time exceeded 5 seconds. Code stopped to prevent crashing.';

  useEffect(() => {
    initializeWorker();
    return () => {
      terminateWorker();
    };
  }, []);

  const initializeWorker = async () => {
    if (!workerRef.current) {
      workerRef.current = new Worker(new URL('../workers/pyodideWorker.js', import.meta.url));

      // Send the interruptBuffer to worker
      workerRef.current.postMessage({ type: 'initialize', interruptBuffer });
      
      workerRef.current.onmessage = (event) => {
        const { results, error } = event.data;
        console.log('onmessage error:', error);
        if (error) {
          handleError(error);
        } else {
          handleSuccess(results);
        }
      };

      workerRef.current.onerror = (error) => {
        handleError(error.message);
      };

      setIsLoading(false);
    }
  };

  const terminateWorker = async () => {
    if (workerRef.current) {
      workerRef.current.terminate();
      workerRef.current = null;
    }
  };

  const restartWorker = async () => {
    if (workerRef.current) {
      workerReset.current = true;
      await terminateWorker();
      await initializeWorker();
    }
  };

  const setErrorMessage = (message?: string | null) => {
    setErrorMessageState(message ?? null);
    errorMessageRef.current = message
  };

  const runScript = async (
    code: string,
    mainExecution = false,
    fetchCommands = false,
    fetchConstants = false
  ): Promise<RunScriptResult> => {
    if (mainExecution) setIsCodeRunning(true);

    if (!workerRef.current) {
      throw new Error('Pyodide worker is not initialized.');
    }

    return new Promise((resolve, reject) => {
      workerRef.current!.onmessage = (event) => {
        const { results, commands, error, constants, updatedConstants } = event.data;
        if (error) {
          if (mainExecution) {
            // Clear the timeout in case of error
            clearTimeout(timeoutRef.current!);
          }
          handleError(error);
          reject(error);
        } else {
          if (mainExecution) {
            // Clear the timeout when script finishes
            clearTimeout(timeoutRef.current!);
          }
          resolve({ results, commands, constants, updatedConstants });
        }
        if (mainExecution) setIsCodeRunning(false);
      };

      // Set the interrupt flag to 0 before running the code
      interruptFlag[0] = 0;
      if (mainExecution) {
        // Start the 5-second timeout after the script starts running
        timeoutRef.current = setTimeout(() => {
          if (interruptFlag[0] === 0) {
            // Set interrupt flag
            interruptFlag[0] = 1;
            handleError(executionInterruptMessage);
          }
        }, 10000);
      }

      if (fetchCommands) {
        workerRef.current!.postMessage({
          type: 'fetch-commands',
          code,
          apiLevelData
        });
      } else if (fetchConstants) {
        workerRef.current!.postMessage({
          type: 'fetch-constants',
          code,
          apiLevelData
        });
      } else {
        workerRef.current!.postMessage({
          type: mainExecution ? 'main-execution' : 'execution',
          code,
          apiLevelData
        });
      }
    });
  };

  const continueExecution = async (code: string) => {
    // used for conditional commands, like Question
    setCommandsInQueue([]);
    setPath([]);
    setLastCommandInProgress(commandInProgress);
    setIsCodeExecuted(false);
    await waitForMs(0);
    await runScript(code, true);
    isProcessingLocked.current = false;
    setIsCodeExecuted(true);
  };

  const postMessageToWorker = (message: any) => {
    if (workerRef.current) {
      workerRef.current.postMessage(message);
    }
  };

  const handleSuccess = async (results: any) => {
    // Handle successful execution if necessary
    workerReset.current = false;
  };

  const stopCodeExecution = async () => {
    await restartWorker();
    resetCommandsQueue();
    setIsCodeRunning(false);
    setIsRunningInitiated(false);
    setIsCodeExecuted(true);
  };

  const handleError = async (message: string) => {
    setIsCodeRunning(false);
    setIsRunningInitiated(false);
    setErrorMessage(message);
    const lines = message.trim().split('\n');
    const errorLine = lines[lines.length - 1];

    // Check if the error is related to long execution
    if (message.includes(executionInterruptMessage)) {
      await confirm({
        description: (
          <Box component="div">
            <p>The execution took too long and was stopped to prevent app crashing.</p>
            <p>Execution was interrupted to keep the application running.</p>
          </Box>
        ),
        title: 'Execution Time Exceeded',
        hideCancelButton: true,
        confirmationText: 'Close',
        confirmationButtonProps: { variant: 'contained' },
        allowClose: false
      });

      // Restart the worker after an infinite loop or timeout
      await restartWorker();
    } else {
      try {
        // now let's get the error annotation using a regular expression.
        // here is an example Python line for an error annotation: "  File "<exec>", line 2, in <module>"
        const errorRow = parseInt(message.match(/File "<exec>", line (\d+)/)?.[1] || '1', 10) - 1;

        setErrorAnnotations([
          {
            row: errorRow,
            column: 0,
            text: message.split('\n').pop() || 'Error',
            type: 'error'
          }
        ]);
      } catch (e) {
        console.log(e);
      }

      await confirm({
        description: (
          <Box component="div">
            <p>
              Oh no! You've ran into an error. This happened either because you did something wrong
              and the interpreter can't understand what you wanted to do, or because something bad
              happened that was unexpected during the execution of your code.
            </p>
            <p>Here is the error:</p>
            <code style={{ color: 'white' }}>
              <pre>{errorLine}</pre>
            </code>

            <p>Here is the full stack trace of the error:</p>
            <pre style={{ fontSize: 12, color: 'white' }}>{message}</pre>
          </Box>
        ),
        title: 'Error',
        hideCancelButton: true,
        confirmationText: 'Close',
        confirmationButtonProps: { variant: 'contained' },
        allowClose: false
      });
    }

    stopCodeExecution();
  };

  const value: PyodideContextType = {
    runScript,
    isLoading,
    errorAnnotations,
    setErrorAnnotations,
    errorMessage,
    setErrorMessageState,
    setErrorMessage,
    isCodeRunning,
    setIsCodeRunning,
    stopCodeExecution,
    continueExecution,
    postMessageToWorker,
    isRunningInitiated,
    setIsRunningInitiated
  };

  return <PyodideContext.Provider value={value}>{children}</PyodideContext.Provider>;
};

export default PyodideContext;
