import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { Alert, Collapse, IconButton, Typography, Box, Link } from '@mui/material';
import advancedCourse from '../assets/courses/adv_course_bg.png';
import { useLocaleContext } from '../context/LocaleContext';

const announcement = {
  message: 'The Advanced Python Course is Here! Jump in and start coding now.'
};

export default function AnnouncementBar() {
  const { getLocaleLink } = useLocaleContext();

  const COOKIE_NAME = 'announcement_closed';
  const COOKIE_EXPIRATION_DAYS = 7;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const isClosed = Cookies.get(COOKIE_NAME);

    if (!isClosed && announcement?.message) {
      setOpen(true);
    }
  }, [announcement]);

  const handleClose = () => {
    Cookies.set(COOKIE_NAME, 'true', { expires: COOKIE_EXPIRATION_DAYS });
    setOpen(false);
  };

  const getButtonLink = () => {
    return getLocaleLink(`/play/advanced-python/generators-level1`)
  };

  return (
    <Collapse in={open}>
      <Alert
        icon={false}
        sx={{
          py: 0,
          backgroundColor: '#0b130c8f',
          fontWeight: 'bold'
        }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            sx={{
              alignSelf: 'center'
            }}
            onClick={handleClose}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
      >
        <Box component="div" display="flex" alignItems="center" gap={1}>
          <img src={advancedCourse} alt="advanced-python-course" style={{ width: '50px' }} />
          <Typography
            sx={{
              ml: 0.5,
              fontSize: '1rem'
            }}
            component="span"
          >
            {announcement?.message}
          </Typography>
          <Link
            href={getButtonLink()}
            color="primary.main"
            sx={{
              fontWeight: 'bold',
              fontSize: '1rem',
              textDecoration: 'none',
              transition: 'text-decoration 0.3s',
              '&:hover': {
                textDecoration: 'none',
                color: 'primary.dark'
              }
            }}
          >
            Play Now →
          </Link>
        </Box>
      </Alert>
    </Collapse>
  );
}
