import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

interface ModalState {
  openModal: boolean;
  content: string;
  image?: string | null;
}

interface GenericModalContextType {
  modals: { [key: string]: ModalState };
  setOpenModal: (key: string, open: boolean) => void;
  setContent: (key: string, content: string, image?: string | null) => void;
}

const GenericModalContext = createContext<GenericModalContextType | undefined>(undefined);

export const GenericModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [modals, setModals] = useState<{ [key: string]: ModalState }>({});

  const setOpenModal = (key: string, open: boolean) => {
    setModals((prevModals) => ({
      ...prevModals,
      [key]: { ...(prevModals[key] || {}), openModal: open }
    }));
  };

  const setContent = (key: string, content: string, image?: string | null) => {
    setModals((prevModals) => ({
      ...prevModals,
      [key]: { ...(prevModals[key] || {}), content, image }
    }));
  };

  return (
    <GenericModalContext.Provider value={{ modals, setOpenModal, setContent }}>
      {children}
    </GenericModalContext.Provider>
  );
};

export const useGenericModal = (key: string) => {
  const context = useContext(GenericModalContext);
  if (context === undefined) {
    throw new Error('useGenericModal must be used within a GenericModalProvider');
  }

  const { modals, setOpenModal, setContent } = context;

  // Initialize modal state if it doesn't exist for the key
  useEffect(() => {
    if (!modals[key]) {
      setOpenModal(key, false);
      setContent(key, '');
    }
  }, [key, modals, setOpenModal, setContent]);

  return {
    openModal: modals[key]?.openModal || false,
    content: modals[key]?.content || '',
    image: modals[key]?.image || '',
    setOpenModal: (open: boolean) => setOpenModal(key, open),
    setContent: (content: string, image?: string | null) => setContent(key, content, image)
  };
};
